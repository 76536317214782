<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="hasPermission('create spin-offs')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="$router.push({name: 'researcher.spin-offs.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="spinoffs"
              :sort-mapping="sortMapping"
              :title="'spin-offs'"
              :total="spinoffsTotal"
              :fetch-path="'tecnologyTransfers/fetchSpinoffs'"
              :filter-path="'tecnologyTransfers/filterSpinoff'"
              :export-path="'tecnologyTransfers/exportSpinoffs'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="showInfoSheet(field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View spin-off
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('update spin-offs')"
                        @click="$router.push({name: 'researcher.spin-offs.edit', params: { id: field.id }})"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('delete spin-offs')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="hasAdminPermission('view spin-offs')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Name</label>
                  <input
                    type="text"
                    class="form-control"
                    @change="saveFilter($event.target.value, 'name')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Web</label>
                  <input
                    type="text"
                    class="form-control"
                    @change="saveFilter($event.target.value, 'web')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Entrepreneurs</label>
                  <v-select
                    label="name"
                    multiple
                    :options="entrepeneurs"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'tecnologyTransfers/fetchEntrepeneurs')"
                    @input="saveFilter($event, 'entrepeneur')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Born in (from year)</label>
                  <date-picker
                    v-model="filters['born_in_from']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'born_in_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Born in (to year)</label>
                  <date-picker
                    v-model="filters['born_in_to']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'born_in_to')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Closed in (from year)</label>
                  <date-picker
                    v-model="filters['closed_in_from']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'closed_in_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Closed in (to year)</label>
                  <date-picker
                    v-model="filters['closed_in_to']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'closed_in_to')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >ICREA active</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'researcher_active')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item="{item}">
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Spin-off Company<br>
                      <strong>{{ item.name }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Web<br>
                      <strong><a
                        :href="item.web"
                        title="go to web"
                        target="_blank"
                      >{{ item.web }}</a></strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Description<br>
                      <strong v-html="item.description" /></p>
                  </div>
                  <div class="mb-1">
                    <p>Born in<br>
                      <strong>{{ item.born_in }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Closed in<br>
                      <strong>{{ item.closed_in }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Logo</p>
                    <span v-html="item.icon_url" />
                  </div>
                  <hr>
                  <div class="mb-2">
                    <h4>Entrepreneurs</h4>
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>ICREA member</th>
                          <th class="text-end">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="entrepeneur in item.entrepeneurs"
                          :key="entrepeneur.id"
                        >
                          <td>{{ entrepeneur.name }}</td>
                          <td><span
                            class="badge"
                            :class="`bg-light-${entrepeneur.user_id ? 'success' : 'danger'}`"
                          >{{ entrepeneur.user_id ? 'Yes' : 'No' }}</span></td>
                          <td class="text-end">
                            <a
                              v-if="entrepeneur.user_id"
                              class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
                              :href="$router.resolve({name: 'researcher.dashboard', params: { id: entrepeneur.user_id }}).href"
                              target="_blank"
                            ><i data-feather="user" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h4>Files</h4>
                  <div class="mb-1">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>File name</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="file in item.files"
                          :key="file.id"
                        >
                          <td>{{ file.name }}</td>
                          <td class="text-end">
                            <a
                              :href="file.url"
                              target="_blank"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="download file"
                            ><i data-feather="download" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  v-if="hasPermission('update spin-offs')"
                  class="offcanvas-footer mt-auto"
                >
                  <a
                    title="edit this honour & award"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="$router.push({name: 'researcher.spin-offs.edit', params: { id: item.id }})"
                  >Edit</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      sortedField: 'Spin-off Company',
      defaultFields: [
        {
          name: 'Spin-off Company',
          checked: true,
          order: 1,
        },
        {
          name: 'Icon',
          checked: true,
          order: 2,
        },
        {
          name: 'Web',
          checked: true,
          order: 3,
        },
        {
          name: 'Entrepeneurs',
          checked: true,
          order: 4,
        },
        {
          name: 'Born in',
          checked: true,
          order: 5,
        },
        {
          name: 'Closed in',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        'Spin-off Company': 'name',
        Icon: 'icon_url',
        Web: 'web',
        Entrepeneurs: 'entrepeneurs',
        'Born in': 'born_in',
        'Closed in': 'closed_in',
      },
      title: 'Spin-Offs',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      spinoffs: 'tecnologyTransfers/spinoffs',
      spinoffsTotal: 'tecnologyTransfers/spinoffsTotal',
      entrepeneurs: 'tecnologyTransfers/entrepeneurs',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'spinoffs')
    await this.$store.dispatch('tecnologyTransfers/filterSpinoff', this.filters)

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'spinoffs',
      })
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'tecnologyTransfers/fetchSpinoffs', url: `${Vue.prototype.$groupUrl}/tecnology-transfers/spin-offs/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
